<template lang="pug">
	section.brand
		h2(id="brandTitle") {{ $t(`landingPage.brand.title`) }}
		p.brand__txt(v-html="$t(`landingPage.brand.text`)")
		
		b-tabs(v-if="!isMobile" vertical)
			b-tab(v-for="(item, i) in 4" :key="`brand-${i}`" :active="i == 0")
				template(#title)
					span(@mouseover='activateTab(i)') {{ $t(`landingPage.brand.list[${i}]`) }}
					.landing__btn.is-light-primary.brand__btn(v-if="i == 3" @click="$emit('click', $event)") {{ $t('landingPage.preview.neurobot.button') }}

				img.brand__image(:src="`/assets/img/landingPage/Neuro/brand/image-${i+1}.webp`" :alt="$t(`landingPage.brand.list[${i}]`)")
			
		.accordion(v-if="isMobile" role='tablistBrand')
			div(v-for="(item, index) in 4" :key="`brand-${index}`")
				div(role='tabBrand')
					.nav-link(v-b-toggle="`accordion-brand-${index}`") 
						a {{ $t(`landingPage.brand.list[${index}]`) }}
				b-collapse(:id="`accordion-brand-${index}`" :visible="index === 0" accordion='my-accordion-brand' role='tabpanelBrand')
					img.brand__image(:src="`/assets/img/landingPage/Neuro/brand/image-${index+1}.webp`" :alt="$t(`landingPage.brand.list[${index}]`)" width="auto" height="593")
					.landing__btn.is-light-primary.brand__btn(@click="$emit('click', $event)") {{ $t('landingPage.preview.neurobot.button') }}

</template>

<script>
export default {
	name: 'Brand',
	data: () => ({
		prevIndex: -1
	}),
	methods: {
		activateTab(index) {
			if (index !== this.prevIndex) {
				const navLinkEl = document.querySelectorAll('.brand .nav-tabs .nav-link')[index];
				navLinkEl.click();
				this.prevIndex = index;
			}
		},
	}
}

</script>

<style lang="scss">

.brand {
	padding: 124.5px 0;

	@media (max-width: 991.98px) {
		padding-top: 100px;
		padding-bottom: 83px;
	}

	@media (max-width: 767.98px) {
		padding-top: 75.5px;
		padding-bottom: 41.5px;
	}

	@media (max-width: 575.98px) {
		padding-top: 50px;
		padding-bottom: 0;
	}

	&__txt {
		font-weight: 400;
		line-height: 25px;
		margin-top: 31px;
		margin-bottom: 58px !important;

		@media (max-width: 767.98px) {
			font-weight: 500;
			margin-top: 33px;
			line-height: 24px;
			letter-spacing: -0.011em;
			margin-bottom: 41.5px !important;
		}

		@media (max-width: 575.98px) {
			margin-bottom: 25px !important;
		}
	}

	.tabs {
		.col-auto {
			padding: 0;
			max-width: 442px;
		}

		.tab-content {
			padding: 0 9px 0 93px;
			margin-right: -48px;
			margin-top: 0.5px;

			@media (max-width: 991.98px) {
				padding: 0 0 0 35px;
				margin-right: 0;
			}

			@media (max-width: 767.98px) {
				padding: 0;
			}
		}

		.nav-tabs {
			white-space: normal;
		}

		.nav-link {
			font-size: 32px;
			line-height: 48px;
			margin-bottom: 26px;
			font-weight: 700;
			letter-spacing: -0.011em;
			padding: 0 8px;
			display: inline-block;
			cursor: default;

			@media (max-width: 991.98px) {
				font-size: 28px;
				line-height: 42px;
				margin-bottom: 23px;
			}

			&.active {
				span {
					color: var(--primary-text-color);
				}
		
				&::before {
					display: none;
				}
			}

			span {
				color: var(--lc-light-grayish-silver);
				cursor: pointer;
			}
		}
	}

	&__image {
		width: 100%;
		height: auto;

		@media (max-width: 767.98px) {
			width: calc(100% - 9px);
			margin: 0 auto;
			display: block;
			max-width: 500px;
			max-height: 400px;
		}
	}

	&__btn {
		width: 338px !important;
		margin-top: 26px;
		font-weight: 500;

		@media (max-width: 767.98px) {
			margin: 24px auto 25px;
			width: 100% !important;
			max-width: 400px;
		}
	}

	.accordion {
		.nav-link {
			color: var(--lc-light-grayish-silver);
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 25px;
			font-weight: 700;
			letter-spacing: -0.011em;
			padding: 0;

			a {
				cursor: pointer;
			}
		
			&.not-collapsed {
				color: var(--primary-text-color)
			}
		}
	}
}

</style>